import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '@/components/Layout';
import { menuItems } from '@/config';
import SEO from '@/components/Seo';

export default function ContactPage() {
  return (
    <Layout activeMenu={menuItems.contact}>
      <SEO
        title="Kontakt"
        description="Kontakt Givan Holzarbeiten GmBh"
        keywords={[`Kontakt`]}
      />
      <div className="content-padding flex contact-wrapper">
        <div className="flex-1 contact-text-wrapper">
          <h2 style={{ marginBottom: `0` }}>Givan Holzarbeiten GmbH</h2>
          <div
            style={{
              display: `flex`,
              flexDirection: `column`,
              marginBottom: `50px`,
            }}
          >
            <span>Schwarzenburgstrasse 842</span>
            <span>3145 Niederscherli</span>
            <a style={{ textTransform: `none` }} href="mailto:info@givan.ch">
              info@givan.ch
            </a>
          </div>

          <div
            style={{
              display: `flex`,
              flexDirection: `column`,
              marginBottom: `50px`,
            }}
          >
            <span>Maurice Gilgen</span>
            <a href="tel:0041794893459">0041 (0)79 489 34 59</a>
          </div>

          <div
            style={{
              display: `flex`,
              flexDirection: `column`,
              marginBottom: `50px`,
            }}
          >
            <span>Aljoscha van Der Stad</span>
            <a href="tel:0041792398435">0041 (0)79 239 84 35</a>
          </div>
        </div>
        <div className="contact-image-wrapper">
          <div className="contact-image">
            <StaticImage
              src="../content/images/team/team.jpg"
              layout="constrained"
              width={800}
              alt="Team"
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}
